/**
*
* site custom css
*/
/* variables */
.rouble {
  font-family: 'rouble';
  font-weight: normal;
  font-style: normal;
}
html {
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 320px;
  box-sizing: border-box;
  overflow-x: hidden;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
body {
  display: flex;
  flex-direction: column;
  min-width: 320px;
  font-family: "ubuntu", Helvetica, Tahoma, Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  min-height: 100%;
  overflow-x: hidden;
  background-color: #f4f4f4;
}
.main-content {
  flex-grow: 1;
}
section.std-section {
  position: relative;
  width: 100%;
  max-width: 1280px;
  min-width: 320px;
  background-color: #fff;
  margin-left: auto;
  margin-right: auto;
}
section.dark {
  background-color: #f4f4f4;
}
/**
* Основной контейнер
*/
.container {
  width: 100%;
  max-width: 1140px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: auto;
  margin-right: auto;
}
.container-small {
  max-width: 980px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: auto;
  margin-right: auto;
}
/**
* общие кастомные стили
*/
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
button {
  cursor: pointer;
}
address {
  font-style: normal;
}
/**
* правила для плагина magnific popup
*/
html.no-scroll {
  overflow-y: hidden;
  padding-right: 15px;
}
.mfp-content .mfp-close {
  opacity: 1;
}
.mfp-content .mfp-close .icon {
  width: 25px;
  height: 25px;
  fill: #1d8fe0;
}
.posr {
  position: relative;
}
/**
* footer webmotor
*/
.webmotor span {
  color: #000;
}
.webmotor a {
  color: #000;
  text-decoration: underline;
  transition: 0.15s color ease-in;
}
.page-head {
  margin-bottom: 20px;
}
/**
* Это основной стилевой файл
*/
/**
* файл для стилей для разных устройств
*/
.header-mob-section {
  position: relative;
  padding-top: 10px;
}
.header-mob-section a {
  outline: none;
}
@media (max-width: 767px) {
  .header-mob__bottom {
    display: none;
  }
}
.header-mob__main {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 20px;
}
.header-mob__open {
  position: relative;
  width: 45px;
  height: 45px;
  transition: all ease-in-out 0.1s;
}
.header-mob__open:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-20%);
  width: 25px;
  height: 25px;
  background-image: url('../img/header/mob-menu.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  transition: all ease-in-out 0.1s;
}
.header-mob__close {
  position: relative;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 45px;
  transition: all ease-in-out 0.1s;
}
@media (max-width: 419px) {
  .header-mob__close {
    margin-bottom: 10px;
  }
}
.header-mob__close:before {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  background-image: url('../img/header/mob-close.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  transition: all ease-in-out 0.1s;
}
.header-mob__logo {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: flex-end;
  text-decoration: none;
}
.header-mob__logo_text {
  font-family: "ptsans", Helvetica, Tahoma, Arial, sans-serif;
  font-size: 29px;
  line-height: 23px;
  font-weight: 700;
  color: #017bd0;
  text-transform: uppercase;
  margin-right: 10px;
}
.header-mob__logo_img {
  display: block;
  max-width: 50px;
}
.header-mob__callback_phone {
  font-family: "ptsans", Helvetica, Tahoma, Arial, sans-serif;
  font-size: 17px;
  font-weight: 700;
  color: #000;
}
@media (max-width: 419px) {
  .header-mob__callback_phone {
    display: none;
  }
}
.header-mob__callback_phone a {
  color: #017bd0;
  text-decoration: none;
}
.header-mob__callback_icon {
  width: 32px;
  height: 32px;
}
.header-mob__callback_icon a {
  display: block;
  width: 32px;
  height: 32px;
  background-image: url('../img/icons/header-callback.png');
}
@media (min-width: 420px) {
  .header-mob__callback_icon {
    display: none;
  }
}
.header-mob__callback_fire {
  font-family: "ptsans", Helvetica, Tahoma, Arial, sans-serif;
  color: #000;
  font-size: 13px;
}
.header-mob__search {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  height: 100%;
}
.header-mob__search_form {
  position: relative;
  width: 100%;
}
@media (max-width: 419px) {
  .header-mob__search_form {
    max-width: 95%;
  }
}
.header-mob__search_input {
  width: 100%;
  height: 40px;
  font-family: "ptsans", Helvetica, Tahoma, Arial, sans-serif;
  font-size: 14px;
  color: #666666;
  border: 1px solid #e1e1e1;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 12px;
  padding-right: 32px;
}
.header-mob__search_input:focus {
  outline: none;
}
.header-mob__search_input::placeholder {
  color: #666666;
}
.header-mob__search_input::-webkit-input-placeholder {
  color: #666666;
}
.header-mob__search_input::-moz-placeholder {
  color: #666666;
}
.header-mob__search_input:-moz-placeholder {
  color: #666666;
}
.header-mob__search_input:-ms-input-placeholder {
  color: #666666;
}
.header-mob__search_submit {
  position: absolute;
  top: 1px;
  right: 0;
  width: 39px;
  height: 39px;
  background-color: #1d8fe0;
  border: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 0;
}
.header-mob__search_submit:before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url('../img/icons/search-mob.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
.header-mob__menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: #fff;
  -webkit-box-shadow: 0 3px 5px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 3px 5px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 5px 0px rgba(0, 0, 0, 0.1);
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  z-index: 999;
  display: none;
}
@media (max-width: 419px) {
  .header-mob__menu .col-xs-6 {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
}
.header-mob__menu_top {
  padding-bottom: 20px;
}
.header-mob__menu_pages {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
@media (max-width: 419px) {
  .header-mob__menu_pages {
    padding-bottom: 20px;
  }
}
.header-mob__menu_pages-link {
  font-family: "ptsans", Helvetica, Tahoma, Arial, sans-serif;
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  color: #000;
  margin-bottom: 16px;
}
.header-mob__menu_products {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.header-mob__menu_products-link {
  width: 50%;
  font-family: "ptsans", Helvetica, Tahoma, Arial, sans-serif;
  font-size: 17px;
  color: #000;
  margin-bottom: 14px;
}
.breadcrumb-mob {
  list-style-type: none;
  padding-left: 0;
  padding-right: 0;
  padding-top: 35px;
  padding-bottom: 35px;
  margin: 0;
}
.breadcrumb-mob a {
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  text-transform: none;
  color: #0082dd;
  text-decoration: none;
  padding-right: 5px;
  padding-left: 20px;
}
.breadcrumb-mob a[href] span {
  text-decoration: none;
}
.breadcrumb-mob a[href]:hover span {
  text-decoration: underline;
}
.breadcrumb-mob a span {
  overflow: hidden;
}
.breadcrumb-mob a[href]:hover {
  color: #373737;
}
.breadcrumb-mob a::before {
  position: absolute;
  top: 50%;
  left: 5px;
  content: '';
  width: 14px;
  height: 14px;
  background-image: url(../img/icons/down.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  transform: translateY(-50%) rotate(90deg);
  transition: all ease-in-out 0.1s;
}
.breadcrumb-mob li {
  float: left;
}
.breadcrumb-mob li:last-child a::after {
  display: none;
}
.breadcrumb-mob li.active a {
  color: #373737;
}
.breadcrumb-mob li.active a span {
  text-decoration: none;
}
.search-form {
  width: 100%;
  margin-bottom: 40px;
}
.search-form-input-wrapper {
  position: relative;
  display: inline;
}
.search-form__label {
  display: block;
  margin-bottom: 15px;
}
.search-form__input {
  font-family: "ptsans", Helvetica, Tahoma, Arial, sans-serif;
  display: inline-block;
  width: 100%;
  height: 46px;
  font-size: 16px;
  padding-left: 12px;
  padding-right: 57px;
  border-width: 1px;
  border-color: #e4e4e4;
  border-style: solid;
}
.search-form__input:focus {
  outline: 0;
}
.search-form__button-submit {
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 7px;
  width: 40px;
  height: 40px;
  padding: 0;
  border: 0;
  background-color: transparent;
}
.search-form__button-submit .icon {
  width: 40px;
  height: 40px;
  padding: 7px;
  fill: #e0e0e0;
}
.search-result-list {
  margin-bottom: 40px;
}
.search-result-item {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  margin-bottom: 30px;
}
.search-result-item:last-child {
  margin-bottom: 0;
}
.search-item-icon-wrapper {
  width: 45px;
  min-width: 45px;
  margin-right: 10px;
}
.search-item-icon-inner-wrapper {
  width: 45px;
  height: 45px;
  background-color: #f4f4f4;
  padding: 12px;
}
.search-item-icon-inner-wrapper svg,
.search-item-icon__image {
  display: block;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
.search-item-head {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 5px;
}
.search-item-head:hover .search-item-head__text {
  color: #e54d30;
}
.search-item-head:visited .search-item-head__text {
  color: #022d43;
}
.search-item-head__text {
  color: #1b7eb0;
  text-decoration: underline;
  transition: 0.15s color ease-in;
}
.search-item-head__date {
  color: #666;
  padding-left: 5px;
  text-decoration: none;
}
.search-item__caption .highlight {
  background-color: rgba(57, 181, 73, 0.42);
  transition: 0.15s color ease-in;
}
.search-item__caption .highlight-separate {
  font-weight: bold;
}
@media (max-width: 767px) {
  .content-section .table-responsive {
    padding-top: 50px;
  }
  .content-section .table-responsive:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    background-image: url('../img/icons/horizontal-scroll.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
  }
}
.content-section table {
  width: 100% !important;
  border: none !important;
  border-collapse: collapse !important;
  table-layout: auto !important;
  overflow-x: auto !important;
  padding-top: 30px !important;
}
.content-section table th {
  font-family: "ptsans", Helvetica, Tahoma, Arial, sans-serif !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  color: #000 !important;
  text-align: center !important;
  border: 2px solid #e4e4e4 !important;
  background-color: #f4f4f4 !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.content-section table td {
  font-family: "ptsans", Helvetica, Tahoma, Arial, sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  text-align: center !important;
  border: 2px solid #e4e4e4 !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  transition: background ease-in-out 0.1s !important;
}
.content-section table tbody tr:hover td {
  background-color: #f4f4f4 !important;
}
.content-section .content img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
.content-section .content img,
.content-section .content figure img {
  border: 3px solid #e4e4e4;
}
.content-section .content figure figcaption {
  font-size: 14px;
  color: #777777;
}
.content-section .content figure[style="float:left"] {
  margin-left: 0;
}
.content-section .content figure[style="float:right"] {
  margin-right: 0;
}
.content__doc_filename,
.content .content__doc_filename {
  font-weight: 700;
  font-size: 18px;
}
.content__doc_filetype,
.content .content__doc_filetype {
  color: #777777;
}
.content__doc_download,
.content .content__doc_download {
  position: relative;
  display: inline-block;
  font-weight: 700;
  color: #017bd0;
  text-decoration: none;
  vertical-align: middle;
  padding-left: 30px;
}
.content__doc_download:before,
.content .content__doc_download:before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 20px;
  height: 20px;
  background-image: url('../img/icons/download.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
.content__doc_download:hover,
.content .content__doc_download:hover {
  color: #017bd0;
  text-decoration: underline;
}
.content__doc_item,
.content .content__doc_item {
  margin-bottom: 20px;
}
.content__doc_link,
.content .content__doc_link {
  font-size: 18px;
  color: #017bd0;
  text-decoration: underline;
}
.content__doc_link:hover,
.content .content__doc_link:hover {
  color: #017bd0;
  text-decoration: none;
}
.item-list-mob-section {
  padding-top: 35px;
  padding-bottom: 10px;
}
.item-list-mob__visual {
  position: relative;
  width: 50%;
  max-width: 50%;
  flex-basis: 50%;
  height: 335px;
  min-height: 335px;
  overflow: hidden;
}
@media (max-width: 540px) {
  .item-list-mob__visual {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
}
.item-list-mob__visual img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: auto;
  max-width: 90%;
  height: 100%;
}
.item-list-mob__info {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 50%;
  max-width: 50%;
  flex-basis: 50%;
  padding-top: 20px;
  padding-bottom: 30px;
  padding-left: 10px;
  padding-right: 30px;
}
@media (max-width: 540px) {
  .item-list-mob__info {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
  }
}
.item-list-mob__info_link {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: #000;
  width: 100%;
}
.item-list-mob__info_link:hover {
  text-decoration: underline;
}
.item-list-mob__info_type {
  align-self: stretch;
  font-size: 18px;
  line-height: 1;
  color: #000;
  text-decoration: none;
  margin-bottom: 10px;
  transition: color ease-in-out 0.1s;
}
.item-list-mob__info_type:hover {
  text-decoration: underline;
}
.item-list-mob__info_model {
  font-weight: 500;
  transition: color ease-in-out 0.1s;
}
.item-list-mob__info_tech {
  align-self: stretch;
  padding-top: 50px;
  padding-bottom: 20px;
  color: #777777;
}
@media (max-width: 540px) {
  .item-list-mob__info_tech {
    display: none;
  }
}
.item-list-mob__info_buy {
  width: 100%;
  margin-top: auto;
}
.item-list-mob__wrapper {
  position: relative;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  text-decoration: none;
  background-color: #fff;
  -webkit-box-shadow: 0 0 15px 0 rgba(156, 154, 156, 0.2);
  -moz-box-shadow: 0 0 15px 0 rgba(156, 154, 156, 0.2);
  box-shadow: 0 0 15px 0 rgba(156, 154, 156, 0.2);
  margin-bottom: 20px;
  transition: all ease-in-out 0.1s;
}
@media (max-width: 540px) {
  .item-list-mob__wrapper {
    flex-direction: column;
  }
}
.item-list-mob__wrapper.certified:before {
  content: '';
  position: absolute;
  top: 20px;
  left: 20px;
  width: 40px;
  height: 46px;
  background-image: url('../img/items/certified.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  z-index: 111;
}
.item-list-mob__wrapper:hover {
  -webkit-box-shadow: 0 0 20px 0 rgba(156, 154, 156, 0.4);
  -moz-box-shadow: 0 0 20px 0 rgba(156, 154, 156, 0.4);
  box-shadow: 0 0 20px 0 rgba(156, 154, 156, 0.4);
}
.item-list-mob__wrapper:hover .item-list__info_link,
.item-list-mob__wrapper:hover .item-list__info_type,
.item-list-mob__wrapper:hover .item-list__info_model {
  color: #0082dd;
}
.item-list-mob__adds {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 579px) {
  .item-list-mob__adds {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}
.item-list-mob__adds_caption {
  font-family: "ptsans", Helvetica, Tahoma, Arial, sans-serif;
  font-size: 20px;
  color: #373737;
  margin-bottom: 16px;
  order: 2;
  font-size: 18px;
  line-height: 1.2;
  text-align: left;
  margin-bottom: 5px;
}
@media (max-width: 579px) {
  .item-list-mob__adds_caption {
    order: 2;
    font-size: 18px;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 5px;
  }
}
.item-list-mob__adds img {
  display: block;
  max-width: 100px;
  max-height: 100px;
  order: 1;
  width: 50px;
  max-width: 50px;
  margin-right: 20px;
}
.item-list-mob__adds_wrapper {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  text-decoration: none;
  height: 175px;
  background-color: #fff;
  -webkit-box-shadow: 0 0 15px 0 rgba(156, 154, 156, 0.2);
  -moz-box-shadow: 0 0 15px 0 rgba(156, 154, 156, 0.2);
  box-shadow: 0 0 15px 0 rgba(156, 154, 156, 0.2);
  padding-top: 19px;
  padding-bottom: 14px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
  transition: all ease-in-out 0.1s;
  flex-direction: row;
  width: 49%;
  max-width: 49%;
  flex-basis: 49%;
  height: 70px;
}
@media (min-width: 320px) and (max-width: 579px) {
  .item-list-mob__adds_wrapper {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
    height: auto;
  }
}
.item-list-mob__adds_wrapper.old .item-list__adds_caption {
  color: #c8c8c8;
}
.item-list-mob__adds_wrapper:hover {
  -webkit-box-shadow: 0 0 20px 0 rgba(156, 154, 156, 0.4);
  -moz-box-shadow: 0 0 20px 0 rgba(156, 154, 156, 0.4);
  box-shadow: 0 0 20px 0 rgba(156, 154, 156, 0.4);
}
.item-list-mob__adds_wrapper:hover .item-list__info_type,
.item-list-mob__adds_wrapper:hover .item-list__info_model {
  color: #0082dd;
}
@media (max-width: 767px) {
  .about-section {
    padding-top: 50px;
    padding-bottom: 40px;
    margin-bottom: 5px;
  }
}
@media (max-width: 767px) {
  .about__wrapper {
    display: none;
  }
}
@media (max-width: 1023px) {
  .about__item {
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .about__order {
    display: none;
  }
}
.partners-section-mob {
  padding-top: 35px;
  padding-bottom: 40px;
}
.partners-section h2 {
  margin-bottom: 50px;
}
@media (max-width: 768px) {
  .partners-light-section {
    display: none;
  }
}
@media (max-width: 767px) {
  .partners-light-section {
    display: none;
  }
}
@media (min-width: 768px) {
  .partners-light-section-mob {
    display: none;
  }
}
@media (max-width: 1023px) {
  .partners-light__item_imgwrap img {
    max-width: 90%;
  }
}
@media (max-width: 1024px) {
  .partners-light__prev {
    left: -10px;
  }
}
@media (max-width: 1024px) {
  .partners-light__next {
    right: -10px;
  }
}
@media (max-width: 767px) {
  .questions-section {
    display: none;
  }
}
.object-mob-section {
  padding-top: 30px;
}
@media (max-width: 639px) {
  .object-mob__col {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
}
.object-mob__slider .slick-dots {
  bottom: 13px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-around;
  padding-left: 10%;
  padding-right: 10%;
}
.object-mob__slider .slick-dots li button {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  border: 2px solid #e54d30;
}
.object-mob__slider .slick-dots li.slick-active button {
  background-color: #e54d30;
}
.object-mob__slider .slick-dots li button:before {
  content: '';
}
.object-mob__slider_main {
  position: relative;
}
.object-mob__slider_main-wrapper {
  position: relative;
  border: 4px solid #e4e4e4;
  height: 320px;
}
@media (max-width: 639px) {
  .object-mob__slider_main-wrapper {
    max-width: 340px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }
}
.object-mob__slider_main-item {
  position: relative;
  overflow: hidden;
  height: 312px;
}
.object-mob__slider_main-item img {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  max-height: 100%;
}
.object-mob__top_heading {
  text-align: center;
  padding-bottom: 40px;
}
@media (max-width: 639px) {
  .object-mob__top_heading {
    padding-bottom: 20px;
  }
}
.object-mob__top_heading-type {
  font-family: "ptsans", Helvetica, Tahoma, Arial, sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 39px;
}
.object-mob__top_heading-model {
  font-family: "ptsans", Helvetica, Tahoma, Arial, sans-serif;
  font-size: 24px;
  font-weight: 700;
}
.object-mob__top_certificate {
  position: relative;
  padding-left: 60px;
  margin-bottom: 40px;
}
.object-mob__top_certificate:before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 45px;
  height: 45px;
  background-image: url('../img/icons/cert.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
.object-mob__top_feedback {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
}
.object-mob__top_feedback a.btn {
  width: 290px;
}
@media (max-width: 639px) {
  .object-mob__top_feedback a.btn {
    width: auto;
  }
}
.object-mob__other {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-grow: 1;
  height: 320px;
}
@media (max-width: 639px) {
  .object-mob__other {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 100%;
  }
}
.object-mob__other_tech {
  align-self: stretch;
  padding-top: 10px;
  padding-bottom: 20px;
  color: #777777;
}
@media (max-width: 639px) {
  .object-mob__other_tech {
    display: none;
  }
}
.object-mob__other .object-mob__top_certificate {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: auto;
  height: 45px;
}
.object-mob__info {
  padding-top: 20px;
}
.object-mob__info_tabs.tab {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  border-bottom: 2px solid #e4e4e4;
}
@media (max-width: 768px) {
  .object-mob__info_tabs.tab {
    justify-content: space-around;
  }
}
.object-mob__info_tabs.tab li {
  position: relative;
  top: 2px;
  flex-grow: unset;
  font-family: "ptsans", Helvetica, Tahoma, Arial, sans-serif;
  font-weight: 400;
  padding-left: 25px;
  padding-right: 25px;
  z-index: 1;
}
@media (max-width: 639px) {
  .object-mob__info_tabs.tab li {
    padding-left: 2px;
    padding-right: 2px;
    margin-right: 0;
    margin-left: 0;
  }
}
.object-mob__info_tabs.tab li a {
  color: #424141;
  background-color: transparent;
  border-bottom: 3px solid transparent;
  padding-top: 16px;
  padding-bottom: 16px;
  transition: all ease-in-out 0.1s;
}
@media (max-width: 639px) {
  .object-mob__info_tabs.tab li a {
    font-size: 13px;
  }
}
.object-mob__info_tabs.tab li:hover a {
  border-bottom: 3px solid #9e9a9a;
}
.object-mob__info_tabs.tab li.active {
  font-weight: 700;
}
.object-mob__info_tabs.tab li.active a {
  color: #424141;
  background-color: transparent;
  border-bottom: 3px solid #1d8fe0;
}
.object-mob__info_content {
  padding-top: 40px;
  padding-bottom: 50px;
  margin-bottom: 0;
}
@media (max-width: 768px) {
  .products-slider-section {
    display: none;
  }
}
@media (max-width: 767px) {
  .products-slider-section {
    display: none;
  }
}
@media (min-width: 768px) {
  .products-slider-section-mob {
    display: none;
  }
}
@media (max-width: 1024px) {
  .products-slider__prev {
    left: -10px;
  }
}
@media (max-width: 1024px) {
  .products-slider__next {
    right: -10px;
  }
}
.footer-mob-section {
  padding-top: 30px;
  padding-bottom: 35px;
}
@media (max-width: 420px) {
  .footer-mob-section .col-xs-6 {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
}
.footer-mob__top {
  padding-bottom: 25px;
}
.footer-mob__top_phone {
  font-family: "ptsans", Helvetica, Tahoma, Arial, sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: #000;
  text-decoration: none;
  border-bottom: 1px dotted #000;
  transition: all ease-in-out 0.1s;
}
.footer-mob__top_phone:hover {
  color: #0082dd;
  border-color: #0082dd;
}
.footer-mob__top_callback {
  font-family: "ptsans", Helvetica, Tahoma, Arial, sans-serif;
  font-size: 17px;
  color: #373737;
  outline: none;
}
.footer-mob__top_callback:hover {
  text-decoration: none;
}
.footer-mob__menu {
  padding-bottom: 30px;
}
.footer-mob__menu_products-wrapper {
  position: relative;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
@media (max-width: 420px) {
  .footer-mob__menu_products-wrapper {
    padding-bottom: 16px;
  }
}
.footer-mob__menu_products-link {
  width: 50%;
  font-family: "ptsans", Helvetica, Tahoma, Arial, sans-serif;
  font-size: 17px;
  color: #000;
  margin-bottom: 12px;
}
.footer-mob__menu_products-link:hover {
  text-decoration: none;
}
.footer-mob__menu_pages-wrapper {
  position: relative;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.footer-mob__menu_pages-link {
  font-family: "ptsans", Helvetica, Tahoma, Arial, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  color: #000;
  text-decoration: none;
  text-transform: uppercase;
  margin-bottom: 20px;
  transition: color ease-in-out 0.1s;
}
.footer-mob__menu_pages-link:hover {
  color: #0082dd;
}
.footer-mob__bottom_info {
  font-family: "ptsans", Helvetica, Tahoma, Arial, sans-serif;
  font-size: 14px;
  color: #666666;
}
@media (max-width: 420px) {
  .footer-mob__bottom_info {
    padding-bottom: 25px;
  }
}
.footer-mob__bottom_info-adress {
  margin-bottom: 12px;
}
.footer-mob__bottom_fullpage {
  font-family: "ptsans", Helvetica, Tahoma, Arial, sans-serif;
  font-size: 17px;
  color: #666666;
}
.footer-mob__bottom_fullpage:hover {
  text-decoration: none;
}
