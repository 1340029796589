// header-mob start

.header-mob {

  &-section {

    position: relative;

    padding-top: 10px;

    & a {

      outline: none;

    }

  }

  &__bottom {

    @media (max-width: 767px) {

      display: none;

    }

  }

  &__main {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;

    justify-content: space-between;

    align-items: flex-end;

    padding-bottom: 20px;
  }

  &__open {
    position: relative;
    width: 45px;
    height: 45px;

    transition: all ease-in-out 0.1s;

    &:before {
      content: '';

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-20%);

      width: 25px;
      height: 25px;

      background-image: url('../img/header/mob-menu.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;

      transition: all ease-in-out 0.1s;
    }
  }

  &__close {
    position: relative;

    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 45px;

    transition: all ease-in-out 0.1s;

    @media (max-width: 419px) {

      margin-bottom: 10px;

    }

    &:before {
      content: '';

      position: absolute;
      // top: 50%;
      // left: 50%;
      // transform: translateX(-50%) translateY(0);

      width: 20px;
      height: 20px;

      background-image: url('../img/header/mob-close.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;

      transition: all ease-in-out 0.1s;
    }
  }

  &__logo {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: flex-end;
    
    text-decoration: none;

    &_text {
      font-family: @secondary-font;
      font-size: 29px;
      line-height: 23px;
      font-weight: 700;
      color: #017bd0;
      text-transform: uppercase;

      margin-right: 10px;
    }

    &_img {
      display: block;

      max-width: 50px;
    }
  }

  &__callback {

    &_phone {
      font-family: @secondary-font;
      font-size: 17px;
      font-weight: 700;
      color: #000;

      @media (max-width: 419px) {

        display: none;

      }
      
      & a {
        color: #017bd0;
        text-decoration: none;
      }
    }

    &_icon {

      width: 32px;
      height: 32px;

      & a {

        display: block;

        width: 32px;
        height: 32px;

        background-image: url('../img/icons/header-callback.png');

      }

      @media (min-width: 420px) {

        display: none;

      }

    }

    &_fire {
      font-family: @secondary-font;
      color: #000;
      font-size: 13px;
    }

  }

  &__search {

    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;

    height: 100%;

    &_form {
      position: relative;

      width: 100%;

      @media (max-width: 419px) {

        max-width: 95%;

      }
    }

    &_input {

      width: 100%;
      height: 40px;

      font-family: @secondary-font;
      font-size: 14px;

      color: #666666;

      border: 1px solid #e1e1e1;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;

      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 12px;
      padding-right: 32px;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: #666666;
      }
      &::-webkit-input-placeholder {
        color: #666666;
      }
      &::-moz-placeholder {
        color: #666666;
      }
      &:-moz-placeholder {
        color: #666666;
      }
      &:-ms-input-placeholder {
        color: #666666;
      }
    }

    &_submit {
      position: absolute;
      top: 1px;
      right: 0;

      width: 39px;
      height: 39px;

      background-color: #1d8fe0;
      border: none;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;

      padding: 0;

      &:before {
        content: '';
        
        display: inline-block;
        
        width: 20px;
        height: 20px;

        background-image: url('../img/icons/search-mob.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
      }
    }

  }

  &__menu {
    
    position: fixed;
    top: 0;
    left: 0;

    width: 100vw;

    background-color: #fff;

    -webkit-box-shadow: 0 3px 5px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0 3px 5px 0px rgba(0,0,0,0.1);
    box-shadow: 0 3px 5px 0px rgba(0,0,0,0.1);

    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;

    z-index: 999;

    display: none;

    @media (max-width: 419px) {

      & .col-xs-6 {

        width: 100%;
        max-width: 100%;
        flex-basis: 100%;

      }

    }

    &_top {
  
      padding-bottom: 20px;

    }

    &_pages {

      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      @media (max-width: 419px) {

        padding-bottom: 20px;

      }

      &-link {

        font-family: @secondary-font;
        font-size: 18px;
        font-weight: 700;
        text-decoration: none;
        text-transform: uppercase;

        color: #000;

        margin-bottom: 16px;

      }

    }

    &_products {

      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;

      &-link {

        width: 50%;

        font-family: @secondary-font;
        font-size: 17px;

        color: #000;
        margin-bottom: 14px;

      }

    }

  }

}

// header-mob stop

// breadcrumb start

.breadcrumb-mob {
  @breadcrumb-mob-color-normal:       #0082dd;
  @breadcrumb-mob-color-hover:        #373737;
  @breadcrumb-mob-text-transform:     none;
  @breadcrumb-mob-font-weight:        400;
  @breadcrumb-mob-font-size:          16px;
  @breadcrumb-mob-line-height:        18px;
  @breadcrumb-mob-padding-top:        35px;
  @breadcrumb-mob-padding-bottom:     35px;
  @breadcrumb-mob-icon-display:       inline-block; // "none" или "inline-block"
  @breadcrumb-mob-separator:          '/';


  list-style-type: none;
  padding-left: 0;
  padding-right: 0;
  padding-top: @breadcrumb-mob-padding-top;
  padding-bottom: @breadcrumb-mob-padding-bottom;
  margin: 0;

  & a {
    font-size: @breadcrumb-mob-font-size;
    line-height: @breadcrumb-mob-line-height;
    font-weight: @breadcrumb-mob-font-weight;
    text-transform: @breadcrumb-mob-text-transform;
    color: @breadcrumb-mob-color-normal;
    text-decoration: none;
    padding-right: 5px;
    padding-left: 20px;

    &[href] {
      & span {
        text-decoration: none;
      }

      &:hover {

        & span {
          text-decoration: underline;
        }

      }
    }

    & span {
      overflow: hidden;
    }

    &[href]:hover {
      color: @breadcrumb-mob-color-hover;
    }
    &::before {
      position: absolute;
      top: 50%;
      left: 5px;
      content: '';
      width: 14px;
      height: 14px;
      background-image: url(../img/icons/down.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
      transform: translateY(-50%) rotate(90deg);
      transition: all ease-in-out 0.1s;
    }
  }
  & li {
    float: left;

    &:last-child a::after {
      display: none;
    }
    &.active a {
      color: @breadcrumb-mob-color-hover;
      & span {
        text-decoration: none;
      }
    }
  }
}

// breadcrumb stop

// search start

.search-form {
  width: 100%;
  margin-bottom: 40px;
}

.search-form-input-wrapper {
  position: relative;
  display: inline;
}

.search-form__label {
  display: block;
  margin-bottom: 15px;
}

.search-form__input {
  font-family: @secondary-font;
  display: inline-block;
  width: 100%;
  height: 46px;
  font-size: 16px;
  padding-left: 12px;
  padding-right: 57px;
  border-width: 1px;
  border-color: #e4e4e4;
  border-style: solid;

  &:focus {
    outline: 0;
  }
}

.search-form__button-submit {
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 7px;
  width: 40px;
  height: 40px;
  padding: 0;
  border: 0;
  background-color: transparent;

  & .icon {
    width: 40px;
    height: 40px;
    padding: 7px;
    fill: #e0e0e0;
  }
}

.search-result-list {
  margin-bottom: 40px;
}

.search-result-item {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
}

.search-item-icon-wrapper {
  width: 45px;
  min-width: 45px; // special for ie
  margin-right: 10px;
}

.search-item-icon-inner-wrapper {
  width: 45px;
  height: 45px;
  background-color: #f4f4f4;
  padding: 12px;
}

.search-item-icon-inner-wrapper svg,
.search-item-icon__image {
  display: block;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

.search-item-head {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 5px;

  &:hover .search-item-head__text {
    color: @link-hover;
  }
  &:visited .search-item-head__text {
    color: @link-visited;
  }
}

.search-item-head__text {
  color: @link-color;
  text-decoration: underline;
  transition: 0.15s color ease-in;
}

.search-item-head__date {
  color: #666;
  padding-left: 5px;
  text-decoration: none;
}

.search-item__caption {
    & .highlight {
        background-color: rgba(57, 181, 73, 0.42);
        transition: 0.15s color ease-in;
    }

    & .highlight-separate {
        font-weight: bold;
    }
}

// search stop

// content start

.content-section {

  & .table-responsive {

    @media (max-width: 767px) {
    
      padding-top: 50px;

      &:before {      

        content: '';

        display: block;

        position: absolute;
        top: 0;
        left: 0;

        width: 40px;
        height: 40px;

        background-image: url('../img/icons/horizontal-scroll.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;

      }

    }

  }

  & table {

    width: 100% !important;
    
    border: none !important;
    border-collapse: collapse !important;

    table-layout: auto !important;

    overflow-x: auto !important;

    padding-top: 30px !important;

    & th {

      font-family: @secondary-font !important;
      font-size: 14px !important;
      font-weight: 700 !important;

      color: #000 !important;
      text-align: center !important;

      border: 2px solid #e4e4e4 !important;
      background-color: #f4f4f4 !important;

      padding-left: 10px !important;
      padding-right: 10px !important;
      padding-top: 5px !important;
      padding-bottom: 5px !important;

    }

    & td {

      font-family: @secondary-font !important;
      font-size: 14px !important;
      font-weight: 400 !important;

      text-align: center !important;

      border: 2px solid #e4e4e4 !important;

      padding-left: 10px !important;
      padding-right: 10px !important;
      padding-top: 5px !important;
      padding-bottom: 5px !important;

      transition: background ease-in-out 0.1s !important;

    }

    & tbody {

      & tr {
        
        // cursor: pointer !important;

        &:hover {

          & td {

            background-color: #f4f4f4 !important;

          }

        }

      }

    }

  }

  & .content img {
    
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }

  & .content img, & .content figure img {

    border: 3px solid #e4e4e4;

  }

  & .content figure figcaption {

    font-size: 14px;
    color: #777777;

  }

  & .content figure[style="float:left"] {

    margin-left: 0;

  }

  & .content figure[style="float:right"] {

    margin-right: 0;

  }

}

.content__doc, .content .content__doc {

  &_wrapper {

    

  }

  &_filename {

    font-weight: 700;
    font-size: 18px;

  }

  &_filetype {

    color: #777777;

  }

  &_download {
    
    position: relative;

    display: inline-block;
    
    font-weight: 700;
    color: #017bd0;
    text-decoration: none;
    vertical-align: middle;

    padding-left: 30px;

    &:before {

      content: '';

      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;

      width: 20px;
      height: 20px;

      background-image: url('../img/icons/download.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;

    }

    &:hover {

      color: #017bd0;
      text-decoration: underline;

    }

  }

  &_item {

    margin-bottom: 20px;

  }

  &_link {
    
    font-size: 18px;
    color: #017bd0;
    text-decoration: underline;

    &:hover {
      
      color: #017bd0;
      text-decoration: none;

    }

  }

}

// content stop

// item-list-mob start

.item-list-mob {

  &-section {

    padding-top: 35px;
    padding-bottom: 10px;

  }

  &__visual {

    position: relative;

    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
    height: 335px;
    min-height: 335px;

    overflow: hidden;

    @media (max-width: 540px) {

      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
      // height: auto;

    }

    & img {

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      
      display: block;

      width: auto;
      max-width: 90%;
      height: 100%;

    }

  }

  &__info {

    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
    
    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: 10px;
    padding-right: 30px;

    @media (max-width: 540px) {

      width: 100%;
      max-width: 100%;
      flex-basis: 100%;

      text-align: center;

      padding-top: 10px;
      padding-bottom: 10px;
      padding-right: 10px;

    }

    &_link {

      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-direction: column;

      text-decoration: none;
      color: #000;

      width: 100%;

      &:hover {
        
        text-decoration: underline;

      }

    }

    &_type {

      align-self: stretch;

      font-size: 18px;
      line-height: 1;
      color: #000;
      text-decoration: none;

      margin-bottom: 10px;

      transition: color ease-in-out 0.1s;

      &:hover {

        text-decoration: underline;

      }
    }

    &_model {

      font-weight: 500;

      transition: color ease-in-out 0.1s;

    }

    &_tech {
      align-self: stretch;

      padding-top: 50px;
      padding-bottom: 20px;
      color: #777777;

      @media (max-width: 540px) {
        display: none;
      }

    }

    &_buy {

      width: 100%;
      margin-top: auto;

    }

  }

  &__wrapper {

    position: relative;

    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    

    // max-height: 370px;

    text-decoration: none;

    background-color: #fff;

    -webkit-box-shadow: 0 0 15px 0 rgba(156,154,156,0.2);
    -moz-box-shadow: 0 0 15px 0 rgba(156,154,156,0.2);
    box-shadow: 0 0 15px 0 rgba(156,154,156,0.2);

    margin-bottom: 20px;

    transition: all ease-in-out 0.1s;

    @media (max-width: 540px) {

      flex-direction: column;

    }

    &.certified {

      &:before {

        content: '';

        position: absolute;
        top: 20px;
        left: 20px;

        width: 40px;
        height: 46px;

        background-image: url('../img/items/certified.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;

        z-index: 111;

      }

    }

    &:hover {

      -webkit-box-shadow: 0 0 20px 0 rgba(156,154,156,0.4);
      -moz-box-shadow: 0 0 20px 0 rgba(156,154,156,0.4);
      box-shadow: 0 0 20px 0 rgba(156,154,156,0.4);

      & .item-list__info_link, & .item-list__info_type, .item-list__info_model {

        color: #0082dd;

      }
    }
  }

  &__adds {

    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;

    @media (max-width: 579px) {
      
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-direction: column;

      justify-content: flex-start;

    }

    &_caption {

      font-family: @secondary-font;
      font-size: 20px;
      color: #373737;

      margin-bottom: 16px;

      order: 2;

      font-size: 18px;
      line-height: 1.2;
      text-align: left;

      margin-bottom: 5px;

      @media (max-width: 579px) {

        order: 2;

        font-size: 18px;
        line-height: 1.2;
        text-align: center;

        margin-bottom: 5px;

      }

    }

    & img {

      display: block;

      max-width: 100px;
      max-height: 100px;

      order: 1;

      width: 50px;
      max-width: 50px;

      margin-right: 20px;

    }

    &_wrapper {

      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      text-align: center;
      text-decoration: none;

      height: 175px;

      background-color: #fff;

      -webkit-box-shadow: 0 0 15px 0 rgba(156,154,156,0.2);
      -moz-box-shadow: 0 0 15px 0 rgba(156,154,156,0.2);
      box-shadow: 0 0 15px 0 rgba(156,154,156,0.2);

      padding-top: 19px;
      padding-bottom: 14px;
      padding-left: 20px;
      padding-right: 20px;

      margin-bottom: 20px;

      transition: all ease-in-out 0.1s;

      flex-direction: row;
      
      width: 49%;
      max-width: 49%;
      flex-basis: 49%;
      height: 70px;

      @media (min-width: 320px) and (max-width: 579px) {

        width: 100%;
        max-width: 100%;
        flex-basis: 100%;

        height: auto;

      }

      &.old {

        & .item-list__adds_caption {

          color: #c8c8c8;

        }

      }

      &:hover {

        -webkit-box-shadow: 0 0 20px 0 rgba(156,154,156,0.4);
        -moz-box-shadow: 0 0 20px 0 rgba(156,154,156,0.4);
        box-shadow: 0 0 20px 0 rgba(156,154,156,0.4);

        & .item-list__info_type, .item-list__info_model {

          color: #0082dd;

        }
      }
    }
  }

}

// item-list-mob stop

// about start

.about {
  &-section {

    @media (max-width: 767px) {

      padding-top: 50px;
      padding-bottom: 40px;

      margin-bottom: 5px;

    }

  }

  &__wrapper {

    @media (max-width: 767px) {
      
      display: none;

    }
  }

  &__item {

    @media (max-width: 1023px) {
      margin-bottom: 20px;
    }

  }
  
  &__order {

    @media (max-width: 767px) {
      display: none;
    }

  }

}

// about stop

// partners start

.partners {

  &-section {

    &-mob {

      padding-top: 35px;
      padding-bottom: 40px;

    }

    & h2 {
      margin-bottom: 50px;
    }
    
  }

}

.partners-light {

  &-section {

    @media (max-width: 768px) {

      display: none;

    }

    @media (max-width: 767px) {
      display: none;
    }

    &-mob {

      @media (min-width: 768px) {
        display: none;
      }

    }
    
  }

  &__item {

    &_imgwrap {

      & img {

        @media (max-width: 1023px) {
          max-width: 90%;
        }

      }
    }

  }

  &__prev {

    @media (max-width: 1024px) {
      
      left: -10px;

    }

  }

  &__next {

    @media (max-width: 1024px) {
      
      right: -10px;

    }

  }

}

// partners stop

// question start

.questions {
  
  &-section {

    @media (max-width: 767px) {

      display: none;

    }

  }

}

// question stop

// object-mob start

.object-mob {

  &-section {

    padding-top: 30px;

  }

  &__col {

    @media (max-width: 639px) {

      width: 100%;
      max-width: 100%;
      flex-basis: 100%;

    }

  }

  &__slider {

    & .slick-dots {

      bottom: 13px;

      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: space-around;

      padding-left: 10%;
      padding-right: 10%;

    }

    .slick-dots li button {

      width: 13px;
      height: 13px;

      border-radius: 50%;

      border: 2px solid #e54d30;

    }

    .slick-dots li.slick-active button {
      
      background-color: #e54d30;

    }

    & .slick-dots li button:before {

      content: '';

    }

    &_main {

      position: relative;

      &-wrapper {

        position: relative;

        border: 4px solid #e4e4e4;

        height: 320px;

        @media (max-width: 639px) {
          
          max-width: 340px;

          margin-left: auto;
          margin-right: auto;

          margin-bottom: 20px;

        }

      }

      &-item {

        position: relative;

        overflow: hidden;

        height: 312px;

        & img {

          display: block;

          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);

          max-height: 100%;

        }

      }

    }

  }

  &__top {

    &_heading {

      text-align: center;

      padding-bottom: 40px;

      @media (max-width: 639px) {

        padding-bottom: 20px;

      } 

      &-type {

        font-family: @secondary-font;
        font-size: 24px;
        font-weight: 400;
        line-height: 39px;

      }

      &-model {

        font-family: @secondary-font;
        font-size: 24px;
        font-weight: 700;

      }

    }

    &_certificate {
      
      position: relative;

      padding-left: 60px;
      margin-bottom: 40px;

      &:before {

        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 45px;
        height: 45px;

        background-image: url('../img/icons/cert.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;

      }

    }

    &_feedback {

      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-direction: column;

      & a.btn {

        width: 290px;

        @media (max-width: 639px) {

          width: auto;

        }

      }

    }

  }

  &__other {

    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    flex-grow: 1;
    height: 320px;

    @media (max-width: 639px) {
  
      flex-direction: row;
      justify-content: space-around;
      align-items: center;

      height: 100%;

    }

    &_tech {
      align-self: stretch;

      padding-top: 10px;
      padding-bottom: 20px;
      color: #777777;

      @media (max-width: 639px) {

        display: none;

      }

    }

    & .object-mob__top_certificate {

      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: center;
      align-items: center;

      margin-bottom: auto;

      height: 45px;

    }

  }

  &__info {

    padding-top: 20px;

    &_tabs.tab {

      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: center;

      border-bottom: 2px solid #e4e4e4;

      @media (max-width: 768px) {

        justify-content: space-around;

      }

      & li {
        position: relative;
        top: 2px;

        flex-grow: unset;

        font-family: @secondary-font;
        font-weight: 400;

        padding-left: 25px;
        padding-right: 25px;

        z-index: 1;

        @media (max-width: 639px) {

          padding-left: 2px;
          padding-right: 2px;
          margin-right: 0;
          margin-left: 0;

        }

        & a {
          
          color: @text-color;
          background-color: transparent;

          border-bottom: 3px solid transparent;
          
          padding-top: 16px;
          padding-bottom: 16px;

          transition: all ease-in-out 0.1s;

          @media (max-width: 639px) {

            font-size: 13px;

          }

        }

        &:hover {

          & a {

            border-bottom: 3px solid #9e9a9a;

          }

        }

        &.active {

          font-weight: 700;

          & a {
            
            color: @text-color;
            background-color: transparent;

            border-bottom: 3px solid @primary-color;

          }

        }

      }

    }

    &_content {

      // min-height: 580px;

      padding-top: 40px;
      padding-bottom: 50px;

      margin-bottom: 0;

    }

  }

}

// object-mob stop 

// products-slider start

.products-slider {

  &-section {

    @media (max-width: 768px) {
      
      display: none;

    }

    @media (max-width: 767px) {
      display: none;
    }

    &-mob {

      @media (min-width: 768px) {
        display: none;
      }

    }
    
  }

  &__prev {

    @media (max-width: 1024px) {

      left: -10px;

    }

  }
  &__next {

    @media (max-width: 1024px) {

      right: -10px;

    }

  }

}

// products-slider stop

// footer start

.footer {

  &-mob {
  
    &-section {

      padding-top: 30px;
      padding-bottom: 35px;

      @media (max-width: 420px) {

        .col-xs-6 {

          width: 100%;
          max-width: 100%;
          flex-basis: 100%;

        }        

      }

    }

    &__top {

      padding-bottom: 25px;

      &_phone {

        font-family: @secondary-font;
        font-size: 20px;
        font-weight: 700;

        color: #000;
        text-decoration: none;

        border-bottom: 1px dotted #000;

        transition: all ease-in-out 0.1s;

        &:hover {

          color: #0082dd;
          border-color: #0082dd;

        }

      }

      &_callback {

        font-family: @secondary-font;
        font-size: 17px;

        color: #373737;

        outline: none;

        &:hover {

          text-decoration: none;

        }

      }

    }

    &__menu {

      padding-bottom: 30px;

      &_products {

        &-wrapper {

          position: relative;

          display: -webkit-flex;
          display: -moz-flex;
          display: -ms-flex;
          display: -o-flex;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: flex-start;
          align-items: flex-start;

          @media (max-width: 420px) {

            padding-bottom: 16px;

          }

        }

        &-link {

          width: 50%;

          font-family: @secondary-font;
          font-size: 17px;

          color: #000;

          margin-bottom: 12px;

          &:hover {

            text-decoration: none;

          }

        }

      }

      &_pages {

        &-wrapper {

          position: relative;

          display: -webkit-flex;
          display: -moz-flex;
          display: -ms-flex;
          display: -o-flex;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

        }

        &-link {

          font-family: @secondary-font;
          font-size: 18px;
          font-weight: 700;
          line-height: 18px;

          color: #000;
          text-decoration: none;
          text-transform: uppercase;

          margin-bottom: 20px;

          transition: color ease-in-out 0.1s;

          &:hover {

            color: #0082dd;

          }

        }

      }

    }

    &__bottom {

      &_info {

        font-family: @secondary-font;
        font-size: 14px;

        color: #666666;

        @media (max-width: 420px) {

          padding-bottom: 25px;

        }

        &-adress {

          margin-bottom: 12px;

        }

        &-copyright {

          

        }

      }

      &_fullpage {

        font-family: @secondary-font;
        font-size: 17px;

        color: #666666;

        &:hover {

          text-decoration: none;

        }

      }

    }

  }

}

// footer stop
